<template>
  <v-container
    id="register"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <v-slide-y-transition appear>
          <v-card
            class="pa-3 pa-md-5 mx-auto"
            light
            v-if="jornada"
          >
            <pages-heading
              class="text-center display-3"
              back
            >
              {{ $t('app.general.detail_of')}}
              {{ $t('app.jornadas.single') }}

              <template v-slot:subheading>
                {{ jornada.torneo.nombre }}
              </template>
            </pages-heading>

            <v-card-content>
              <v-row>
                <v-col
                  cols="5"
                  lg="4"
                >
                  <base-material-card
                    color="transparent"
                    image
                  >
                    <template v-slot:image>
                      <v-img
                        :src="jornada.equipo_local.logotipo || logo"
                        :lazy-src="preloader"
                      />
                    </template>
                    <v-card-title class="display-2 font-weight-medium">
                      {{ jornada.equipo_local.nombre }}
                    </v-card-title>

                    <v-card-text>
                      <v-list avatar two-line subheader>
                        <v-subheader>
                          {{ $t('app.general.formation') }}
                        </v-subheader>
                        <v-list-item
                          v-for="(player, index) in jornada.equipo_local.jugadores"
                          :key="`local-player-${index}`"
                        >
                          <v-list-item-avatar>
                            <v-img
                              :lazy-src="preloader"
                              :src="player.persona.fotografia"
                              v-if="player.persona.fotografia"
                            ></v-img>
                            <v-avatar
                              color="primary"
                              v-else
                            >
                              <span class="white--text">
                                {{ tokenizeName(player.persona.fullname) }}
                              </span>
                            </v-avatar>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ player.persona.fullname }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ player.pivot.posicion.nombre }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </base-material-card>
                </v-col>
                <v-col
                  cols="2"
                  offset-lg="1"
                  align-self="center"
                >
                  <v-img
                    :lazy-src="preloader"
                    :src="versusImg"
                    class="center-element"
                  ></v-img>
                </v-col>
                <v-col
                  cols="5"
                  lg="4"
                  offset-lg="1"
                >
                  <base-material-card
                    color="transparent"
                    image
                  >
                    <template v-slot:image>
                      <v-img
                        :src="jornada.equipo_visitante.logotipo || logo"
                        :lazy-src="preloader"
                      />
                    </template>
                    <v-card-title class="display-2 font-weight-medium">
                      {{ jornada.equipo_visitante.nombre }}
                    </v-card-title>

                    <v-card-text>
                      <v-list avatar two-line subheader>
                        <v-subheader>
                          {{ $t('app.general.formation') }}
                        </v-subheader>
                        <v-list-item
                          v-for="(player, index) in jornada.equipo_visitante.jugadores"
                          :key="`local-player-${index}`"
                        >
                          <v-list-item-avatar>
                            <v-img
                              :lazy-src="preloader"
                              :src="player.persona.fotografia"
                              v-if="player.persona.fotografia"
                            ></v-img>
                            <v-avatar
                              color="primary"
                              v-else
                            >
                              <span class="white--text">
                                {{ tokenizeName(player.persona.fullname) }}
                              </span>
                            </v-avatar>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ player.persona.fullname }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ player.pivot.posicion.nombre }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </base-material-card>
                </v-col>
              </v-row>
            </v-card-content>
          </v-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PagesJornadaDetalle',

    components: {
      PagesBtn: () => import('./components/Btn'),
      PagesHeading: () => import('./components/Heading'),
    },

    data: () => ({
      jornada: null,
      versusImg: require('../../assets/icons/versus.svg'),
    }),
    async mounted() {
      this.toggleLoader()
      this.jornada = await this.getJornada()
      this.toggleLoader()
    },
    methods: {
      async getJornada () {
        let jornada = null

        await this.$http.post(route('v1/journey/search'), {
          filters: {
            id: this.$route.params.id
          }
        }).then(response => {
          if (response.body.code === 200) {
            jornada = response.body.data
            if (jornada.length > 0) {
              jornada = jornada[0]
            } else {
              jornada = null
            }
          } else {
            this.processError(response)
          }
        }, error => this.processError(error))

        return jornada
      }
    }
  }
</script>

<style lang="sass">
  #register
    .v-list-item__subtitle
      -webkic-line-clamp: initial
      -webkit-box-orient: initial

  .mw50
    max-width: 50% !important

  .versus
    font-weight: bold
    font-style: italic
    margin-right: 5px
</style>
